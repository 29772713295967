exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-at-qriomatrix-js": () => import("./../../../src/pages/life-at-qriomatrix.js" /* webpackChunkName: "component---src-pages-life-at-qriomatrix-js" */),
  "component---src-pages-onboarding-js": () => import("./../../../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-glamezy-js": () => import("./../../../src/pages/projects/Glamezy.js" /* webpackChunkName: "component---src-pages-projects-glamezy-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-kots-js": () => import("./../../../src/pages/projects/kots.js" /* webpackChunkName: "component---src-pages-projects-kots-js" */),
  "component---src-pages-projects-matinee-js": () => import("./../../../src/pages/projects/matinee.js" /* webpackChunkName: "component---src-pages-projects-matinee-js" */),
  "component---src-pages-projects-raintree-js": () => import("./../../../src/pages/projects/raintree.js" /* webpackChunkName: "component---src-pages-projects-raintree-js" */),
  "component---src-pages-projects-rr-js": () => import("./../../../src/pages/projects/rr.js" /* webpackChunkName: "component---src-pages-projects-rr-js" */),
  "component---src-pages-projects-subtraid-js": () => import("./../../../src/pages/projects/Subtraid.js" /* webpackChunkName: "component---src-pages-projects-subtraid-js" */),
  "component---src-pages-projects-tinfit-js": () => import("./../../../src/pages/projects/tinfit.js" /* webpackChunkName: "component---src-pages-projects-tinfit-js" */),
  "component---src-pages-projects-video-corral-js": () => import("./../../../src/pages/projects/VideoCorral.js" /* webpackChunkName: "component---src-pages-projects-video-corral-js" */),
  "component---src-pages-q-data-js": () => import("./../../../src/pages/qData.js" /* webpackChunkName: "component---src-pages-q-data-js" */),
  "component---src-pages-qqueue-js": () => import("./../../../src/pages/qqueue.js" /* webpackChunkName: "component---src-pages-qqueue-js" */),
  "component---src-pages-qrio-shift-js": () => import("./../../../src/pages/qrioShift.js" /* webpackChunkName: "component---src-pages-qrio-shift-js" */),
  "component---src-pages-services-accelerating-startups-js": () => import("./../../../src/pages/services/accelerating-startups.js" /* webpackChunkName: "component---src-pages-services-accelerating-startups-js" */),
  "component---src-pages-services-application-modernization-js": () => import("./../../../src/pages/services/application-modernization.js" /* webpackChunkName: "component---src-pages-services-application-modernization-js" */),
  "component---src-pages-services-cloud-enablement-js": () => import("./../../../src/pages/services/cloud-enablement.js" /* webpackChunkName: "component---src-pages-services-cloud-enablement-js" */),
  "component---src-pages-services-data-science-and-engineering-js": () => import("./../../../src/pages/services/data-science-and-engineering.js" /* webpackChunkName: "component---src-pages-services-data-science-and-engineering-js" */),
  "component---src-pages-services-full-stack-development-js": () => import("./../../../src/pages/services/full-stack-development.js" /* webpackChunkName: "component---src-pages-services-full-stack-development-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-mobile-applications-js": () => import("./../../../src/pages/services/mobile-applications.js" /* webpackChunkName: "component---src-pages-services-mobile-applications-js" */),
  "component---src-pages-services-testing-and-automation-js": () => import("./../../../src/pages/services/testing-and-automation.js" /* webpackChunkName: "component---src-pages-services-testing-and-automation-js" */),
  "component---src-pages-services-user-experience-js": () => import("./../../../src/pages/services/user-experience.js" /* webpackChunkName: "component---src-pages-services-user-experience-js" */),
  "component---src-pages-streameg-js": () => import("./../../../src/pages/streameg.js" /* webpackChunkName: "component---src-pages-streameg-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

